import styled from "styled-components";
import { tsWidth, tsHeight } from "@ecomm/typography";

export const MainCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  background-color: #060607;
  position: relative;
  color: white;
  display: block;
`;
