import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { tsWidth, tsLogo, tsHeight, sliderDescr } from "@ecomm/typography";
import { tsbgblack1, white } from "@ecomm/colors";
import { Images } from "constants/Images";
import { Api } from "api/Api";
import { EntryCollection } from "contentful";
import { ICsTouchscreenScreenTypeFields } from "types/contentful";

const SiteMenuCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  padding-bottom: 225px;
  background-color: ${tsbgblack1};
  position: relative;
  color: ${white};
  display: block;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: absolute;
`;

const Logo = styled.div`
  /* background-image: url(${Images.globalGlowLogo}); */
  ${tsLogo};
  position: absolute;
`;

const SiteMenuTitle = styled.div`
  margin-bottom: 40px;
  ${sliderDescr};
  color: white;
`;

const SiteMenuGrid = styled.div`
  ${tsWidth};
  ${tsHeight};
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  > a {
    display: block;
    width: 300px;
    background: #181a1d;
    padding: 20px 10px;
    border-radius: 100px;
    border: 1px solid white;
    color: white;
    margin: 0 auto 20px;
    ${sliderDescr};
    &:active {
      opacity: 0.7;
    }
  }
`;

export const SiteMenu = (props: RouteComponentProps) => {
  const intlLocation = props.location.pathname.split("/")[1];
  const [screenTypes, setScreenTypes] = useState<EntryCollection<
    ICsTouchscreenScreenTypeFields
  > | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      Api.getScreenTypes().then((response) => setScreenTypes(response));
    };
    fetchData();
  }, []);

  return (
    <SiteMenuCanvas>
      <Logo />
      <SiteMenuGrid>
        <SiteMenuTitle>Choose which touchscreen app to load:</SiteMenuTitle>
        {screenTypes &&
          screenTypes.items.map((item, i) => (
            <a href={`${intlLocation}/${item.fields.path}`} key={i}>
              {item.fields.name}
            </a>
          ))}
        <a href={intlLocation + `/app`}>App</a>
        <a href={intlLocation + `/ipad`}>iPad</a>
      </SiteMenuGrid>
    </SiteMenuCanvas>
  );
};
