import { transparentize } from "polished";

// Solids
export const black = "#000000";
export const offBlack = "#181a1d";
export const white = "#ffffff";
export const offWhite = "#f5f7f9";
export const snowWhite = "#f9fafb";
export const borderGray = "#e4e6e7";
export const mediumGray = "#777a81";
export const lightGray = "#a8acb1";
export const slate = "#697180";
export const superRed = "#ff3347";
export const superRedDark = "#d9182b";
export const yellow = "#ffe28c";
export const darkBlue = "#406599";
export const blueBlack = "#222529";

// touch screen colors
export const tsbgblack1 = "#060607";
export const tstxtgray1 = "#888b93";

// Semi-transparent
export const disabledGray = transparentize(0.4, lightGray);
export const dividerGray = transparentize(0.7, lightGray);
export const transparentBlack = transparentize(0.2, offBlack);
export const dropShadowGray = transparentize(0.9, offBlack);
