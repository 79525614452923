import React, {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "@reach/router";

type NavContextProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  toggleNav: () => void;
  closeNav: () => void;
  openNav: () => void;
  back: () => void;
};

export const NavContext = createContext<NavContextProps>({} as NavContextProps);

type NavProviderProps = {
  children: ReactNode;
  standAlone: boolean;
  goBack: () => void;
};
export const NavProvider = ({
  children,
  standAlone,
  goBack,
}: NavProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleNav = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const closeNav = useCallback(() => {
    setOpen(false);
  }, []);
  const openNav = useCallback(() => {
    setOpen(true);
  }, []);
  const setNavState = useCallback(() => {
    // console.log("setNavState", location.pathname);
    location.pathname.includes("/main") ? openNav() : closeNav();
  }, [location, closeNav, openNav]);

  const back = useCallback(() => {
    standAlone ? goBack() : navigate(-1);
  }, [goBack, navigate, standAlone]);

  useEffect(setNavState, [setNavState]);

  useEffect(() => {
    const duration = 60000;
    // undo when done
    // timeout updated here
    // const duration = 6000000;
    let interval = setInterval(() => goBackToStartScreen(), duration);
    const goBackToStartScreen = () => {
      const locationArray = location.pathname.split("/");
      const intlLocation = locationArray[1];
      const curSection = locationArray[2];
      if (locationArray.length > 2) {
        clearInterval(interval);
        if (standAlone) {
          navigate(`/${intlLocation}/ipad`, { replace: false });
        } else {
          navigate(`/${intlLocation}/${curSection}`, { replace: false });
        }
      }
    };

    const handleClick = () => {
      clearInterval(interval);
      interval = setInterval(() => goBackToStartScreen(), duration);
    };

    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
      clearInterval(interval);
    };
  }, [location, navigate, standAlone]);
  return (
    <NavContext.Provider
      value={{
        open,
        setOpen,
        toggleNav,
        closeNav,
        openNav,
        back,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const NavConsumer = NavContext.Consumer;
