import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Navigation } from "components/Navigation";
import { MainCanvas } from "components/UI/MainCanvas";

import { Images } from "constants/Images";
import styled from "styled-components";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { app2Headline } from "@ecomm/typography";

const TabBack = styled.a`
  display: none;

  ${ccIpadPortrait`
    position: absolute;
    left: 122px;
    top: 48px;
    z-index: 20;
    color: #888b93;
    display: block;
    font-weight: 700;
    font-family: "EuropaGroteskSH-MedExt", Arial, sans-serif;
    -webkit-letter-spacing: 0.1em;
    -moz-letter-spacing: 0.1em;
    -ms-letter-spacing: 0.1em;
    letter-spacing: 0.1em;
    line-height: 1.375em;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.3;
    background-size: 10px;
    padding-left: 26px;
    background-image: url(${Images.globalBtnNavBackBtn});
    background-position: left center;
    background-repeat: no-repeat;
    /* test */
  `}
`;

const AppSubcontainer = styled.div`
  display: block;
  position: relative;
  padding: 83px 0px 150px 90px;
  background: #181a1d;
  min-height: 100vh;
`;

const AppTitle = styled.div`
  color: #cdff38;
  text-transform: uppercase;
  font-size: 30px;
  font-family: "EuropaGroteskSH-MedExt";
  position: absolute;
  left: 93px;
  top: 80px;
`;

const AppSubtitle = styled.div`
  padding-top: 50px;
  display: block;
  position: relative;
  margin-bottom: 67px;
  ${app2Headline}
`;

const AppTile = styled.div`
  display: block;
  position: relative;
  width: 900px;
  height: auto;
  margin-bottom: 46px;
  img {
    width: 900px;
    height: auto;
  }
`;

export const Classes = (props: RouteComponentProps) => {
  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const { navigate } = props;

  const intlLocation = props.location.pathname.split("/")[1];

  return (
    <MainCanvas>
      {standAlone && (
        <TabBack
          onMouseUp={() =>
            navigate && navigate(`/${intlLocation}/ipad`, { replace: true })
          }
        >
          Home
        </TabBack>
      )}
      <AppSubcontainer>
        <AppTitle>Peloton &nbsp; App</AppTitle>
        <AppSubtitle>
          Classes for every
          <br />
          mood, level and goal
        </AppSubtitle>
        <AppTile>
          <img alt="" src={Images.app3classAudioMeditation} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classBikeBootcamp} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classCardio} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classOutdoor} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classRide} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classRow} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classRunning} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classStrength} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classStretch} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classTreadBootcamp} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classWalk} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app3classYoga} />
        </AppTile>
      </AppSubcontainer>
      <Navigation></Navigation>
      <a href="/" className="hidden-btn">
        &nbsp;&nbsp;
      </a>
    </MainCanvas>
  );
};
