/*eslint @typescript-eslint/no-explicit-any: "off"*/
import { css } from 'styled-components';

/*
  The pointer media query value 'fine' refers to mouse and stylus devices, whereas 'coarse' refers to touch.
  This feature is not supported in Firefox or IE11.
  The -moz-touch-enabled property is firefox-specific and covers for firefox touch devices.
  We don't need to support IE11 touch devices, so any IE browser will apply hover styles.
  We select IE devices by using all valid values of the -ms-high-contrast query.
 */

// prettier-ignore
export const hover = (...args: any[]) => css`
  @media (-moz-touch-enabled: 0), (pointer:fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover {
      ${(css as any)(...args)}
    }
  }
`;
