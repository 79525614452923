import styled from 'styled-components';
import { tsWidth, tsHeight } from '@ecomm/typography';
import { tsbgblack1 } from '@ecomm/colors';
import { Images } from 'constants/Images';

export const LoadSpinner = styled.div`
  ${tsWidth};
  ${tsHeight};
  background-color: ${tsbgblack1};
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-image: url(${Images.spinner});
  background-size: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -99999px;
`;
