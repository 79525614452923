import React from "react";
import { Router } from "@reach/router";
import OnRouteChange from "reach-router-scroll-top";
import { Main } from "pages/Main";
import { PackageHome } from "pages/PackageHome";
import { StartScreen } from "pages/StartScreen";
import { Accessories } from "pages/Accessories";
import { SiteMenu } from "pages/SiteMenu";
import { TabletStartScreen } from "pages/TabletStartScreen";
import { createHistory, LocationProvider } from "@reach/router";
import { useReachMemorySource } from "utils/history";
import { NavProvider } from "context/NavContext";
import RouterPage from "components/RouterPage";
import RoutesContainer from "components/RoutesContainer";
import { LocationMenu } from "pages/LocationMenu";
import { GraphQLClient, ClientContext } from "graphql-hooks";
import { isLocal, isStaging } from "utils/dataUtils";
import { Features } from "pages/Features";
import { Classes } from "pages/Classes";
import { Collections } from "pages/Collections";
import { Programs } from "pages/Programs";
import { Memberships } from "pages/Memberships";

const App = () => {
  const { history, goBack } = useReachMemorySource();
  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const configureHistory = () => {
    return standAlone ? history : createHistory(window);
  };

  // TODO: revert hardcode to prod
  const client = new GraphQLClient({
    url:
      isLocal() || isStaging()
        ? "https://graph.prod.k8s.onepeloton.com/graphql"
        : "https://graph.prod.k8s.onepeloton.com/graphql",
  });

  client.setHeader("Content-Type", "application/json");
  client.setHeader("Accept", "application/json");

  let h = configureHistory();
  return (
    <ClientContext.Provider value={client}>
      <LocationProvider history={h}>
        <NavProvider standAlone={standAlone} goBack={goBack}>
          <Router>
            <LocationMenu path="/" />
            <RouterPage path="/:location" pageComponent={RoutesContainer}>
              <RouterPage path="/" pageComponent={SiteMenu} />
              <RouterPage path=":section" pageComponent={StartScreen} />
              <RouterPage path="ipad" pageComponent={TabletStartScreen} />
              <RouterPage
                path="/:section/main/features"
                pageComponent={Features}
              />
              <RouterPage
                path="/:section/main/classes"
                pageComponent={Classes}
              />
              <RouterPage
                path="/:section/main/programs"
                pageComponent={Programs}
              />
              <RouterPage
                path="/:section/main/collections"
                pageComponent={Collections}
              />
              <RouterPage
                path="/:section/main/memberships"
                pageComponent={Memberships}
              />
              <RouterPage
                path="/:section/main/:category"
                pageComponent={Main}
              />
              <RouterPage
                path="/:section/package/:category/:package"
                pageComponent={PackageHome}
              />
              <RouterPage
                path="/:section/accessories/:slide"
                pageComponent={Accessories}
              />
            </RouterPage>
          </Router>
          <OnRouteChange />
        </NavProvider>
      </LocationProvider>
    </ClientContext.Provider>
  );
};

export default App;
