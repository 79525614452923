/* eslint-disable @typescript-eslint/no-var-requires */

// Global
const globalClose = require("assets/images/global/close_icon.png");
const globalDetailPlus = require("assets/images/global/detail_plus_icon.png");
const globalGlowLogo = require("assets/images/global/global_glow_logo.png");
const globalTopGlow = require("assets/images/global/global_top_glow.png");
const globalLargeArrowLeft = require("assets/images/global/large_arrow_left.png");
const globalLargeArrowRight = require("assets/images/global/large_arrow_right.png");
const accessShade = require("assets/images/global/acc-shade.png");
const spinner = require("assets/images/global/spinner.gif");

const globalBtnNavBackBtn = require("assets/images/global/buttons/nav-back-arrow.png");
const globalBtnModalCloseTapped = require("assets/images/global/buttons/global_modal_close_tapped.png");
const globalBtnModalCloseDefault = require("assets/images/global/buttons/global_modal_close_default.png");
const globalBtnDetailTapped = require("assets/images/global/buttons/global_detail_button_tapped.png");
const globalBtnDetailDefault = require("assets/images/global/buttons/global_detail_button_default.png");
const globalBtnArrowRight = require("assets/images/global/buttons/global_arrow_right.png");
const globalBtnArrowRightTapped = require("assets/images/global/buttons/global_arrow_right_tapped.png");
const globalBtnArrowLeft = require("assets/images/global/buttons/global_arrow_left.png");
const globalBtnArrowLeftTapped = require("assets/images/global/buttons/global_arrow_left_tapped.png");
const globalBtnScreensaverTapped = require("assets/images/global/buttons/bike_screensaver_button_tapped.png");
const globalBtnScreensaverDefault = require("assets/images/global/buttons/bike_screensaver_button_default.png");

const redArrowRight22 = require("assets/images/global/red-arrow-right-22.png");

// Nav (Screensaver and Packages Menu)
// --- Bike
const navPackage1 = require("assets/images/nav/bike_home_packages_1.png");
const navPackage2 = require("assets/images/nav/bike_home_packages_2.png");
const navPackage3 = require("assets/images/nav/bike_home_packages_3.png");
const navScreensaver1 = require("assets/images/nav/bike_screensaver_1.jpg");
const navScreensaver2 = require("assets/images/nav/bike_screensaver_2.jpg");
const navScreensaver3 = require("assets/images/nav/bike_screensaver_3.jpg");
// --- Titan Bike
const navPackagePlus1 = require("assets/images/nav/titan_home_packages_card1.png");
const navPackagePlus2 = require("assets/images/nav/titan_home_packages_card2.png");
const navPackagePlus3 = require("assets/images/nav/titan_home_packages_card3.png");
const navScreensaverPlus1 = require("assets/images/nav/titan_screensaver_1.jpg");
const navScreensaverPlus2 = require("assets/images/nav/titan_screensaver_2.jpg");
const navScreensaverPlus3 = require("assets/images/nav/titan_screensaver_3.jpg");
// --- Tread
const navPackTread1 = require("assets/images/nav/tread_home_packages_card1.png");
const navPackTread2 = require("assets/images/nav/tread_home_packages_card2.png");
const navPackTreade3 = require("assets/images/nav/tread_home_packages_card3.png");
const navTreadScreensaver1 = require("assets/images/nav/tread_screensaver_1.jpg");
const navTreadScreensaver2 = require("assets/images/nav/tread_screensaver_2.jpg");
const navTreadScreensaver3 = require("assets/images/nav/tread_screensaver_3.jpg");
// --- Yoga
const navPackYoga1 = require("assets/images/nav/yoga_home_package.jpg");
const navYogaScreensaver1 = require("assets/images/nav/yoga_screensaver.jpg");
// --- Strength
const navPackStrength1 = require("assets/images/nav/slide_strength_home_package.jpg");
const navStrengthScreensaver1 = require("assets/images/nav/strength_screensaver.jpg");

// --- Row rower
// const navPackStrength1 = require("assets/images/nav/slide_strength_home_package.jpg");
const rowScreenSaverCopy = require("assets/images/global/row-time1.png");
const navRowScreensaver1 = require("assets/images/global/row-screensaver-fpo.jpg");
const rowFormAssistFeature = require("assets/images/nav/row_form_assist_feature.png");
const rowRatingFeature = require("assets/images/nav/row_rating_feature.png");
const rowPaceFeature = require("assets/images/nav/row_pace_feature.png");
const rowDetailFormAssist = require("assets/images/nav/animations_FormAssist_Dark_half.gif");
const rowDetailRating = require("assets/images/nav/animations_FormRating_Dark_half.gif");
const rowDetailPace = require("assets/images/nav/animations_PaceTarget_Dark_half.gif");
const rowRedStroke = require("assets/images/global/row-red-border2.png");
const rowRedClose = require("assets/images/global/button-close-redstroke.png");
const rowPackage0 = require("assets/images/global/row_home_packages_card_0_.png");
const rowPackage1 = require("assets/images/global/tread_row_packages_card-2.png");
const rowPackage2 = require("assets/images/global/tread_row_packages_card-3.png");

// Global Chiclets
const chicFlyingShoesDef = require("assets/images/chiclets/flyingshoes.png");
const chicFlyingShoesActive = require("assets/images/chiclets/flyingshoes_active.png");
const chicDumbellDef = require("assets/images/chiclets/dumbbells.png");
const chicDumbellActive = require("assets/images/chiclets/dumbbells_active.png");
const chicMatDef = require("assets/images/chiclets/mat.png");
const chicMatActive = require("assets/images/chiclets/mat_active.png");
const chicReversibleMatDef = require("assets/images/chiclets/reversible_mat.png");
const chicReversibleMatActive = require("assets/images/chiclets/reversible_mat_active.png");
const chicReversibleMat2Def = require("assets/images/chiclets/reversible_mat_red.png");
const chicReversibleMat2Active = require("assets/images/chiclets/reversible_mat_active_red.png");

const chicYogaBlocksDef = require("assets/images/chiclets/yoga_blocks.png");
const chicYogaBlocksActive = require("assets/images/chiclets/yoga_blocks_active.png");
const chicYogaStrapDef = require("assets/images/chiclets/yoga_strap.png");
const chicYogaStrapActive = require("assets/images/chiclets/yoga_strap_active.png");
const chicResistanceBandsDef = require("assets/images/chiclets/resistance_bands.png");
const chicResistanceBandsActive = require("assets/images/chiclets/resistance_bands_active.png");
const chicGlassBottleDef = require("assets/images/chiclets/glass_bottle.png");
const chicGlassBottleActive = require("assets/images/chiclets/glass_bottle_active.png");
const chicEarBudsDef = require("assets/images/chiclets/earbuds.png");
const chicEarBudsActive = require("assets/images/chiclets/earbuds_active.png");
const chicHeartRateDef = require("assets/images/chiclets/heartrate_monitor.png");
const chicHeartRateActive = require("assets/images/chiclets/heartrate_monitor_active.png");
const chicBootWeightsDef = require("assets/images/chiclets/weights.png");
const chicBootWeightsActive = require("assets/images/chiclets/weights_active.png");
const chicBootJBLEarbudsDef = require("assets/images/chiclets/jbl_earbuds.png");
const chicBootJBLEarbudsActive = require("assets/images/chiclets/jbl_earbuds_active.png");
const chicListDef = require("assets/images/chiclets/list.png");
const chicListActive = require("assets/images/chiclets/list_active.png");
// packages chiclets
const chicBikeFamilyDef = require("assets/images/chiclets/bike_family_package.png");
const chicBikeFamilyActive = require("assets/images/chiclets/bike_family_package_active.png");
const chicBikeEssentialsDef = require("assets/images/chiclets/bike_essentials_package.png");
const chicBikeEssentialsActive = require("assets/images/chiclets/bike_essentials_package_active.png");
const chicBikeWorksDef = require("assets/images/chiclets/bike_works_package.png");
const chicBikeWorksActive = require("assets/images/chiclets/bike_works_package_active.png");
const chicTitanEssentialsDef = require("assets/images/chiclets/titan_essentials_package.png");
const chicTitanEssentialsActive = require("assets/images/chiclets/titan_essentials_package_active.png");
const chicTitanFamilyDef = require("assets/images/chiclets/titan_family_package.png");
const chicTitanFamilyActive = require("assets/images/chiclets/titan_family_package_active.png");
const chicTitanWorksDef = require("assets/images/chiclets/titan_works_package.png");
const chicTitanWorksActive = require("assets/images/chiclets/titan_works_package_active.png");
const chicTreadEssentialsDef = require("assets/images/chiclets/tread_essentials.png");
const chicTreadEssentialsActive = require("assets/images/chiclets/tread_essentials_active.png");
const chicTreadFamilyDef = require("assets/images/chiclets/tread_family.png");
const chicTreadFamilyActive = require("assets/images/chiclets/tread_family_active.png");
const chicTreadWorksDef = require("assets/images/chiclets/tread_works.png");
const chicTreadWorksActive = require("assets/images/chiclets/tread_works_active.png");
const chicStrengthPackageDef = require("assets/images/chiclets/strength_package.png");
const chicStrengthPackageActive = require("assets/images/chiclets/strength_package_active.png");
const chicYogaPackageDef = require("assets/images/chiclets/yoga_package.png");
const chicYogaPackageActive = require("assets/images/chiclets/yoga_package_active.png");

// Global Slide Main Images (big ones, will animate up)
const slideMainFlyingShoes = require("assets/images/slide-mains/slide_flyingshoes.png");
const slideMainDumbell = require("assets/images/slide-mains/slide_dumbbells.png");
const slideMainMat = require("assets/images/slide-mains/slide_mat.png");
const slideMainReversibleMat = require("assets/images/slide-mains/slide_reversible_mat.png");

const slideMainReversibleMat2 = require("assets/images/slide-mains/slide_reversible_mat_red.png");

const slideMainYogaBlocks = require("assets/images/slide-mains/slide_yoga_blocks.png");
const slideMainYogaStrap = require("assets/images/slide-mains/slide_yoga_strap.png");
const slideMainResistanceBands = require("assets/images/slide-mains/slide_resistance_bands.png");
const slideMainGlassBottle = require("assets/images/slide-mains/slide_glass_bottle.png");
const slideMainHeartRate = require("assets/images/slide-mains/slide_heartrate_monitor.png");
const slideMainEarBuds = require("assets/images/slide-mains/slide_earbuds.png");
const slideMainBootWeights = require("assets/images/slide-mains/slide_boot_weights.png");
const slideMainRunner = require("assets/images/slide-mains/slide_circuit_runners.png");
const slideMainJBLEarbuds = require("assets/images/slide-mains/slide_jbl_earbuds.png");
// slide main packages (THE FIRST SLIDE)
const slideMainBikeEssentials = require("assets/images/slide-mains/slide_bike_essentials.jpg");
const slideMainBikeFamily = require("assets/images/slide-mains/slide_bike_family.jpg");
const slideMainBikeWorks = require("assets/images/slide-mains/slide_bike_works.jpg");
const slideMainTitanEssentials = require("assets/images/slide-mains/slide_titan_essentials.jpg");
const slideMainTitanFamily = require("assets/images/slide-mains/slide_titan_family.jpg");
const slideMainTitanWorks = require("assets/images/slide-mains/slide_titan_works.jpg");
const slideMainTreadEssentials = require("assets/images/slide-mains/slide_tread_essentials.jpg");
const slideMainTreadFamily = require("assets/images/slide-mains/slide_tread_family.jpg");
const slideMainTreadWorks = require("assets/images/slide-mains/slide_tread_works.jpg");
const slideMainYogaPackage = require("assets/images/slide-mains/slide_yoga_home_package.jpg");
const slideMainStrengthPackage = require("assets/images/slide-mains/slide_strength_home_package.jpg");

// Overlay images and icons
const OverFlyingShoesImg = require("assets/images/overlays/detail_flyingshoes.png");
const OverFlyingShoesIcon1 = require("assets/images/overlays/mesh_icon.png");
const OverFlyingShoesIcon2 = require("assets/images/overlays/shoe_icon.png");
const OverDumbellImg = require("assets/images/overlays/detail_dumbbells.png");
const OverDumbellIcon1 = require("assets/images/overlays/grip_icon.png");
const OverDumbellIcon2 = require("assets/images/overlays/bike_icon.png");
const OverEarBudsImg = require("assets/images/overlays/detail_Earbuds.png");
const OverEarBudsIcon1 = require("assets/images/overlays/earbuds_icon.png");
const OverEarBudsIcon2 = require("assets/images/overlays/washable_icon.png");
const OverMatImg = require("assets/images/overlays/detail_mat.png");
const OverMatIcon1 = require("assets/images/overlays/clean_icon.png");
const OverHeartRateImg = require("assets/images/overlays/detail_heartrate_monitor.png");
const OverHeartRateIcon1 = require("assets/images/overlays/bluetooth_icon.png");
const OverGlassBottleImg = require("assets/images/overlays/detail_glass_bottle.png");
const OverGlassBottleIcon1 = require("assets/images/overlays/water_icon.png");
const OverReversibleMatImg = require("assets/images/overlays/detail_reversible_mat.png");
const OverReversibleMatImg2 = require("assets/images/overlays/detail_reversible_mat_red.png");

const OverReversibleMatIcon1 = require("assets/images/overlays/reversible_icon.png");
const OverYogaBlocksImg = require("assets/images/overlays/detail_yoga_blocks.png");
const OverYogaBlocksIcon1 = require("assets/images/overlays/foam_icon.png");
const OverYogaBlocksIcon2 = require("assets/images/overlays/edges_icon.png");
const OverYogaStrapImg = require("assets/images/overlays/detail_yoga_strap.png");
const OverYogaStrapIcon1 = require("assets/images/overlays/wash_icon.png");
const OverYogaStrapIcon2 = require("assets/images/overlays/measure_icon.png");
const OverResistanceBandsImg = require("assets/images/overlays/detail_resistance_bands.png");
const OverResistanceBandsIcon1 = require("assets/images/overlays/grip_icon2.png");
const OverBootWeightsImg = require("assets/images/overlays/detail_weights.png");
const OverRunnerImg = require("assets/images/overlays/circuit_runners_details.png");
const OverBootWeightsIcon1 = require("assets/images/overlays/weightgrip_icon.png");
const OverBootWeightsIcon2 = require("assets/images/overlays/weightshock_icon.png");
const OverJBLEarbudsImg = require("assets/images/overlays/detail_jbl_earbuds.png");
const OverJBLEarbudsIcon1 = require("assets/images/overlays/audio_icon.png");
const OverJBLEarbudsIcon2 = require("assets/images/overlays/ear_icon.png");
const OverRunnerIcon1 = require("assets/images/overlays/runner1_icon.png");
const OverRunnerIcon2 = require("assets/images/overlays/runner2_icon.png");

//  bike a la carte
const bikeAlacarte1 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte1.png");
const bikeAlacarte2 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte2.png");
const bikeAlacarte3 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte3.png");
const bikeAlacarte4 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte4.png");
const bikeAlacarte5 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte5.png");
const bikeAlacarte6 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte6.png");
const bikeAlacarte7 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte7.png");
const bikeAlacarte8 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte8.png");
const bikeAlacarte9 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte9.png");
const bikeAlacarte10 = require("assets/images/alacarte/bike-alacarte/bike_home_alacarte10.png");

//  strength a la carte
const strengthAlacarte1 = require("assets/images/alacarte/strength-alacarte/strength_home_alacarte1.png");
const strengthAlacarte2 = require("assets/images/alacarte/strength-alacarte/strength_home_alacarte2.png");
const strengthAlacarte3 = require("assets/images/alacarte/strength-alacarte/strength_home_alacarte3.png");
const strengthAlacarte4 = require("assets/images/alacarte/strength-alacarte/strength_home_alacarte4.png");
const strengthAlacarte5 = require("assets/images/alacarte/strength-alacarte/strength_home_alacarte5.png");
const strengthAlacarte6 = require("assets/images/alacarte/strength-alacarte/strength_home_alacarte6.png");
const strengthAlacarte7 = require("assets/images/alacarte/strength-alacarte/strength_home_alacarte7.png");

//  tread a la carte
const treadAlacarte1 = require("assets/images/alacarte/tread-alacarte/tread_home_alacarte1.png");
const treadAlacarte2 = require("assets/images/alacarte/tread-alacarte/tread_home_alacarte2.png");
const treadAlacarte3 = require("assets/images/alacarte/tread-alacarte/tread_home_alacarte3.png");
const treadAlacarte4 = require("assets/images/alacarte/tread-alacarte/tread_home_alacarte4.png");
const treadAlacarte5 = require("assets/images/alacarte/tread-alacarte/tread_home_alacarte5.png");
const treadAlacarte6 = require("assets/images/alacarte/tread-alacarte/tread_home_alacarte6.png");
const treadAlacarte7 = require("assets/images/alacarte/tread-alacarte/tread_home_alacarte7.png");

//  yoga a la carte
const yogaAlacarte1 = require("assets/images/alacarte/yoga-alacarte/yoga_home_alacarte1.png");
const yogaAlacarte2 = require("assets/images/alacarte/yoga-alacarte/yoga_home_alacarte2.png");
const yogaAlacarte3 = require("assets/images/alacarte/yoga-alacarte/yoga_home_alacarte3.png");
const yogaAlacarte4 = require("assets/images/alacarte/yoga-alacarte/yoga_home_alacarte4.png");

// --- App section

const appSectionTitle = require("assets/images/app/pelo-app-title.png");
const appScreenSaver = require("assets/images/app/app_screensaver_export.jpg");
const appClassStrength = require("assets/images/app/class/STRENGTH.png");
const appClassYoga = require("assets/images/app/class/YOGA.png");
const appClassCardio = require("assets/images/app/class/CARDIO.png");
const appClassMeditation = require("assets/images/app/class/MEDITATION.png");
const appClassRunning = require("assets/images/app/class/RUNNING.png");
const appClassOutdoor = require("assets/images/app/class/OUTDOOR.png");
const appClassCycling = require("assets/images/app/class/CYCLING.png");
const appClassStretching = require("assets/images/app/class/STRETCHING.png");
const appClassTreadBoot = require("assets/images/app/class/TREAD-BOOTCAMP.png");
const appClassBikeBoot = require("assets/images/app/class/BIKE-BOOTCAMP.png");
const appClassWalking = require("assets/images/app/class/WALKING.png");

const appProgramOutdoors = require("assets/images/app/programs/OUTDOORS.png");
const appProgramArms = require("assets/images/app/programs/ARMS.png");
const appProgramUsain = require("assets/images/app/programs/USAIN.png");
const appProgramBoxing = require("assets/images/app/programs/BOXING.png");
const appProgramBootcamp = require("assets/images/app/programs/BOOTCAMP.png");
const appProgramCore = require("assets/images/app/programs/CORE.png");
const appProgramCycling = require("assets/images/app/programs/CYCLING.png");
const appProgramMatty = require("assets/images/app/programs/MATTY.png");
const appProgramYoga = require("assets/images/app/programs/YOGA.png");
const appProgramTwentysix = require("assets/images/app/programs/twenty6.2.png");

const appCollectionsAshton = require("assets/images/app/collections/ASHTON.png");
const appCollectionsBeatles = require("assets/images/app/collections/BEATLES.png");
const appCollectionsBarre = require("assets/images/app/collections/BARRE.png");
const appCollectionsBeyonce = require("assets/images/app/collections/BEYONCE.png");
const appCollectionsLogan = require("assets/images/app/collections/LOGAN.png");
const appCollectionsDance = require("assets/images/app/collections/DANCE-CARDIO.png");
const appCollectionsDelta = require("assets/images/app/collections/DELTA.png");
const appCollectionsAFO = require("assets/images/app/collections/AFO.png");
const appCollectionsRunners = require("assets/images/app/collections/RUNNERS.png");
const appCollectionsWelcome = require("assets/images/app/collections/WELCOME.png");

const appCollectionsLizzo = require("assets/images/app/collections/lizzo.png");
const appCollectionsSheeran = require("assets/images/app/collections/ed-sheeran.png");
const appCollectionsChampions = require("assets/images/app/collections/champions.png");

// --- App 2.0
const app2OnthegoOutdoors = require("assets/images/app/onthego/OUTDOORS.9360cdf7.png");
const app2OnthegoMeditation = require("assets/images/app/onthego/APP_INTRO-TO-MEDITATION.png");
const app2OnthegoRoad5k = require("assets/images/app/onthego/APP_ROAD-TO-5K.png");
const app2OnthegoCore = require("assets/images/app/onthego/CORE.19fff09c.png");
const app2OnthegoFloorBoot = require("assets/images/app/onthego/APP_FLOOR-BOOTCAMP.png");
const app2gpGp1 = require("assets/images/app/gymplans/gp1.png");
const app2gpGp2 = require("assets/images/app/gymplans/gp2.png");
const app2gpGp3 = require("assets/images/app/gymplans/gp3.png");
const app2gpGp4 = require("assets/images/app/gymplans/gp4.png");
const app2gpGp5 = require("assets/images/app/gymplans/gp5.png");
const app2gpGp6 = require("assets/images/app/gymplans/gp6.png");
const app2AppTier = require("assets/images/app/apptier/app-tier.png");
const app2AppTierCA = require("assets/images/app/apptier/app-tier-ca.png");

const app3ontgAdapted = require("assets/images/app/onthego2/Adapted.jpg");
const app3ontgBoxing = require("assets/images/app/onthego2/Boxing.jpg");
const app3ontgDanceCardio = require("assets/images/app/onthego2/Dance-Cardio.jpg");
const app3ontgDensity = require("assets/images/app/onthego2/Density.jpg");
const app3ontgMobility = require("assets/images/app/onthego2/Mobility.jpg");
const app3ontgSelfCare = require("assets/images/app/onthego2/Self-Care.jpg");
const app3ontgYinYoga = require("assets/images/app/onthego2/Yin-Yoga.jpg");

const app3ontgAdaptedDetail = require("assets/images/app/onthego2details/adapted-Strength-Detail.jpg");
const app3ontgBoxingDetail = require("assets/images/app/onthego2details/boxing-detail.jpg");
const app3ontgDanceCardioDetail = require("assets/images/app/onthego2details/Dance-Cardio-Detail.jpg");
const app3ontgDensityDetail = require("assets/images/app/onthego2details/Denisty-Detail-Image.jpg");
const app3ontgMobilityDetail = require("assets/images/app/onthego2details/Mobility-Detail.jpg");
const app3ontgSelfCareDetail = require("assets/images/app/onthego2details/Self-Care-Retreat-Detail.jpg");
const app3ontgYinYogaDetail = require("assets/images/app/onthego2details/Yin-Yoga-Detail.jpg");

const app3classAudioMeditation = require("assets/images/app/classes2/audio-meditation.jpg");
const app3classBikeBootcamp = require("assets/images/app/classes2/Bike-bootcamp.jpg");
const app3classCardio = require("assets/images/app/classes2/Cardio.jpg");
const app3classOutdoor = require("assets/images/app/classes2/Outdoor.jpg");
const app3classRide = require("assets/images/app/classes2/Ride.jpg");
const app3classRow = require("assets/images/app/classes2/Row.jpg");
const app3classRunning = require("assets/images/app/classes2/Running.jpg");
const app3classStrength = require("assets/images/app/classes2/Strength.jpg");
const app3classStretch = require("assets/images/app/classes2/Stretch.jpg");
const app3classTreadBootcamp = require("assets/images/app/classes2/Tread-Bootcamp.jpg");
const app3classWalk = require("assets/images/app/classes2/Walk.jpg");
const app3classYoga = require("assets/images/app/classes2/Yoga.jpg");

const atGym2phone = require("assets/images/app/atgym2/phone.png");
const atGym2frame = require("assets/images/app/atgym2/frame.png");
const atGym2intro = require("assets/images/app/atgym2/introducing.png");
const atGym2gym = require("assets/images/app/atgym2/pelo-gym.png");

const appCheckCircle = require("assets/images/app/checkCircle.svg");

export const Images: { [key: string]: string } = {
  appSectionTitle,
  appScreenSaver,
  app2OnthegoOutdoors,
  app2OnthegoMeditation,
  app2OnthegoRoad5k,
  app2OnthegoCore,
  app2OnthegoFloorBoot,
  app2gpGp1,
  app2gpGp2,
  app2gpGp3,
  app2gpGp4,
  app2gpGp5,
  app2gpGp6,
  app2AppTier,
  app2AppTierCA,

  atGym2phone,
  atGym2frame,
  atGym2intro,
  atGym2gym,

  appCheckCircle,

  app3classAudioMeditation,
  app3classBikeBootcamp,
  app3classCardio,
  app3classOutdoor,
  app3classRide,
  app3classRow,
  app3classRunning,
  app3classStrength,
  app3classStretch,
  app3classTreadBootcamp,
  app3classWalk,
  app3classYoga,

  app3ontgAdapted,
  app3ontgBoxing,
  app3ontgDanceCardio,
  app3ontgDensity,
  app3ontgMobility,
  app3ontgSelfCare,
  app3ontgYinYoga,

  app3ontgAdaptedDetail,
  app3ontgBoxingDetail,
  app3ontgDanceCardioDetail,
  app3ontgDensityDetail,
  app3ontgMobilityDetail,
  app3ontgSelfCareDetail,
  app3ontgYinYogaDetail,

  appCollectionsLizzo,
  appCollectionsSheeran,
  appCollectionsChampions,
  appCollectionsAshton,
  appCollectionsBeatles,
  appCollectionsBarre,
  appCollectionsBeyonce,
  appCollectionsLogan,
  appCollectionsDance,
  appCollectionsDelta,
  appCollectionsAFO,
  appCollectionsRunners,
  appCollectionsWelcome,
  appProgramOutdoors,
  appProgramArms,
  appProgramUsain,
  appProgramBoxing,
  appProgramBootcamp,
  appProgramCore,
  appProgramCycling,
  appProgramMatty,
  appProgramYoga,
  appProgramTwentysix,
  appClassStrength,
  appClassYoga,
  appClassCardio,
  appClassMeditation,
  appClassRunning,
  appClassOutdoor,
  appClassCycling,
  appClassStretching,
  appClassTreadBoot,
  appClassBikeBoot,
  appClassWalking,
  rowScreenSaverCopy,
  rowRedClose,
  globalClose,
  globalDetailPlus,
  globalGlowLogo,
  globalTopGlow,
  globalLargeArrowLeft,
  globalLargeArrowRight,
  globalBtnModalCloseTapped,
  globalBtnModalCloseDefault,
  globalBtnDetailTapped,
  globalBtnDetailDefault,
  globalBtnArrowRight,
  globalBtnArrowRightTapped,
  globalBtnArrowLeft,
  globalBtnArrowLeftTapped,
  globalBtnScreensaverTapped,
  globalBtnScreensaverDefault,
  redArrowRight22,
  spinner,
  accessShade,
  navPackage1,
  navPackage2,
  navPackage3,
  navScreensaver1,
  navScreensaver2,
  navScreensaver3,
  navPackagePlus1,
  navPackagePlus2,
  navPackagePlus3,
  navScreensaverPlus1,
  navScreensaverPlus2,
  navScreensaverPlus3,
  globalBtnNavBackBtn,
  navPackTread1,
  navPackTread2,
  navPackTreade3,
  navTreadScreensaver1,
  navTreadScreensaver2,
  navTreadScreensaver3,
  navPackYoga1,
  navYogaScreensaver1,
  navPackStrength1,
  navStrengthScreensaver1,
  navRowScreensaver1,

  chicFlyingShoesDef,
  chicFlyingShoesActive,
  chicDumbellDef,
  chicDumbellActive,
  chicMatDef,
  chicMatActive,
  chicGlassBottleDef,
  chicGlassBottleActive,
  chicListDef,
  chicListActive,
  chicReversibleMatDef,
  chicReversibleMatActive,
  chicReversibleMat2Def,
  chicReversibleMat2Active,
  chicYogaBlocksDef,
  chicYogaBlocksActive,
  chicYogaStrapDef,
  chicYogaStrapActive,
  chicResistanceBandsDef,
  chicResistanceBandsActive,
  chicEarBudsDef,
  chicEarBudsActive,
  chicHeartRateDef,
  chicHeartRateActive,
  chicBootWeightsDef,
  chicBootWeightsActive,
  chicBootJBLEarbudsDef,
  chicBootJBLEarbudsActive,
  chicBikeFamilyDef,
  chicBikeFamilyActive,
  chicBikeEssentialsDef,
  chicBikeEssentialsActive,
  chicBikeWorksDef,
  chicBikeWorksActive,
  chicTitanEssentialsDef,
  chicTitanEssentialsActive,
  chicTitanFamilyDef,
  chicTitanFamilyActive,
  chicTitanWorksDef,
  chicTitanWorksActive,
  chicTreadEssentialsDef,
  chicTreadEssentialsActive,
  chicTreadFamilyDef,
  chicTreadFamilyActive,
  chicTreadWorksDef,
  chicTreadWorksActive,
  chicStrengthPackageDef,
  chicStrengthPackageActive,
  chicYogaPackageDef,
  chicYogaPackageActive,

  slideMainFlyingShoes,
  slideMainMat,
  slideMainReversibleMat,
  slideMainReversibleMat2,
  slideMainYogaBlocks,
  slideMainYogaStrap,
  slideMainResistanceBands,
  slideMainGlassBottle,
  slideMainHeartRate,
  slideMainEarBuds,
  slideMainBootWeights,
  slideMainJBLEarbuds,
  slideMainDumbell,
  slideMainBikeEssentials,
  slideMainBikeFamily,
  slideMainBikeWorks,
  slideMainTitanEssentials,
  slideMainTitanFamily,
  slideMainTitanWorks,
  slideMainTreadEssentials,
  slideMainTreadFamily,
  slideMainTreadWorks,
  slideMainYogaPackage,
  slideMainStrengthPackage,
  slideMainRunner,

  OverFlyingShoesImg,
  OverFlyingShoesIcon1,
  OverFlyingShoesIcon2,
  OverDumbellImg,
  OverDumbellIcon1,
  OverDumbellIcon2,
  OverEarBudsImg,
  OverEarBudsIcon1,
  OverEarBudsIcon2,
  OverMatImg,
  OverMatIcon1,
  OverHeartRateImg,
  OverHeartRateIcon1,
  OverGlassBottleImg,
  OverGlassBottleIcon1,
  OverReversibleMatImg,
  OverReversibleMatImg2,
  OverReversibleMatIcon1,
  OverYogaBlocksImg,
  OverYogaBlocksIcon1,
  OverYogaBlocksIcon2,
  OverYogaStrapImg,
  OverYogaStrapIcon1,
  OverYogaStrapIcon2,
  OverResistanceBandsImg,
  OverResistanceBandsIcon1,
  OverBootWeightsImg,
  OverBootWeightsIcon1,
  OverBootWeightsIcon2,
  OverJBLEarbudsImg,
  OverJBLEarbudsIcon1,
  OverJBLEarbudsIcon2,
  OverRunnerImg,
  OverRunnerIcon1,
  OverRunnerIcon2,

  bikeAlacarte1,
  bikeAlacarte2,
  bikeAlacarte3,
  bikeAlacarte4,
  bikeAlacarte5,
  bikeAlacarte6,
  bikeAlacarte7,
  bikeAlacarte8,
  bikeAlacarte9,
  bikeAlacarte10,
  strengthAlacarte1,
  strengthAlacarte2,
  strengthAlacarte3,
  strengthAlacarte4,
  strengthAlacarte5,
  strengthAlacarte6,
  strengthAlacarte7,
  treadAlacarte1,
  treadAlacarte2,
  treadAlacarte3,
  treadAlacarte4,
  treadAlacarte5,
  treadAlacarte6,
  treadAlacarte7,
  yogaAlacarte1,
  yogaAlacarte2,
  yogaAlacarte3,
  yogaAlacarte4,

  rowFormAssistFeature,
  rowPaceFeature,
  rowRatingFeature,
  rowDetailFormAssist,
  rowDetailPace,
  rowDetailRating,
  rowRedStroke,
  rowPackage0,
  rowPackage1,
  rowPackage2,
};
