import React, { useState } from "react";
import styled from "styled-components";
import {
  tsWidth,
  tsHeight,
  overlayCont,
  overlaytitle,
  featureOverlayitem,
  overlayBtn,
  anim1,
} from "@ecomm/typography";
import { black, white, offBlack, tsbgblack1 } from "@ecomm/colors";
import { Images } from "constants/Images";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
// import { Entry } from "contentful";
// import { ICsTouchscreenProductDetailFields } from "types/contentful";

const FeatureOverlayCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  background-color: ${black};
  position: relative;
  color: ${white};
  display: block;
  padding-top: 200px;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: top;
  z-index: 10;
  position: absolute;
`;

const OverlayContainer = styled.div`
  /* ${overlayCont};
  background: ${offBlack}; */
  position: relative;
  margin: 0 auto;
`;

const OverlayStroke = styled.div`
  position: relative;
  border: 3px solid #222222;
  border-radius: 6px;
  margin: 0 auto;
  width: 900px;
  height: 1367px;
`;

const OverlayFrame = styled.div`
  padding: 100px 200px 0px;
  background-image: url(${Images.rowRedStroke});
  background-repeat: no-repeat;
  background-position: center 50px;
  background-size: 800px 700px;
  width: 900px;
  height: 680px;
`;

const OverlayImg = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
    ${ccIpadPortrait`
      width: 550px;
      height: auto;
      /* border: 1px dotted red; */
  `}
  }
`;

export const LoadSpinner = styled.div`
  width: 900px;
  height: 675px;
  background-color: ${tsbgblack1};
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-image: url(${Images.spinner});
  background-size: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -99999px;
  border: 1px solid white;
`;

const OverlayDetail = styled.div`
  text-align: center;
  padding: 0px 80px 0;
`;

const OverlayImgTitle = styled.div`
  ${overlaytitle};
  color: #ff003d;
  padding: 0 0 25px;
  margin: 0;
  font-size: 30px;
  line-height: 1.35em;
`;

const Overlaytitle = styled.div`
  ${overlaytitle};
  color: white;
  padding: 0 0 25px;
  margin: 0;
  font-size: 30px;
  line-height: 1.35em;
`;

const OverlayItem = styled.div`
  ${featureOverlayitem};
  margin: 0;
  padding: 0;
  font-size: 34px;
  line-height: 1.4em;
  padding-bottom: 60px;
`;

const OverlayClose = styled.a`
  background-image: url(${Images.rowRedClose});
  ${overlayBtn};
  ${anim1};
  &:active {
    background-image: url(${Images.globalBtnModalCloseTapped});
  }
`;

interface Props {
  image: string;
  title: string;
  details?: any[] | undefined;
  hideOverlay(): void;
}

export const FeatureOverlay = (props: Props) => {
  const content: any = {
    rowDetailFormAssist: [
      {
        title: "Real-time feedback",
        description:
          "Get real-time feedback to make the most of every stroke, every second. When your form is off, you'll see areas of your body highlighted in red on screen, so you know what to adjust.",
      },
      {
        title: "Personalized Calibration",
        description:
          "Sensors in the seat and handlebars detect your position and recommend personalized ways for you to improve your form.",
      },
    ],
    rowDetailRating: [
      {
        title: "Nobody's perfect. But your form can get close.",
        description:
          "Receive a personalized breakdown of your form after every class, so you can focus on improving your rowing stroke over time.",
      },
      {
        title: "Take your workout personally",
        description:
          "Get access to your personalized metrics like output, heart rate, pace and stroke rate throughout your workout.",
      },
    ],
    rowDetailPace: [
      {
        title: "Challenges at every level.",
        description:
          "No matter how much rowing experience you have, there’s a workout that’s right for you. Customize every class to your ability level so you know how hard to push in any given interval.",
      },
      {
        title: "Target Metrics",
        description:
          "Instructors will guide you through each class by using a combination of Stroke Rate cues and levels of intensity for each interval.",
      },
    ],
  };
  const { hideOverlay, image, title } = props;
  const { details } = {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    details: content[image],
  };

  const [isLoading, setIsLoading] = useState(true);
  const [preloadComplete, setPreloadComplete] = useState<boolean>(false);

  if (!preloadComplete) {
    // TODO: move this to dataUtils
    const promises: Promise<string>[] = [];
    const cacheImages = (arr: string[]) => {
      // TODO: this should only be called once
      // console.log("cacheImages", arr);
      arr.forEach((src) => {
        const p = new Promise<string>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(src);
          img.onerror = () => reject();
        });
        promises.push(p);
      });
      Promise.all(promises).then((results) => {
        setIsLoading(false);
        setPreloadComplete(true);
      });
    };
    if (image && isLoading) cacheImages([Images[image]]);
  }

  return (
    <FeatureOverlayCanvas>
      <OverlayStroke>
        <OverlayContainer className="">
          {isLoading ? (
            <LoadSpinner>Loading...</LoadSpinner>
          ) : (
            <OverlayFrame>
              <OverlayImgTitle>{title}</OverlayImgTitle>
              <OverlayImg>
                <img alt="" src={Images[image]} />
              </OverlayImg>
            </OverlayFrame>
          )}
          <OverlayDetail>
            {details &&
              details.map((item: any, i: number) => (
                <OverlayItem key={i}>
                  <Overlaytitle>{item.title}</Overlaytitle>
                  <div>{item.description}</div>
                </OverlayItem>
              ))}
          </OverlayDetail>
        </OverlayContainer>
      </OverlayStroke>
      <OverlayClose onMouseUp={() => hideOverlay()}></OverlayClose>
    </FeatureOverlayCanvas>
  );
};
