import {
  createHistory,
  createMemorySource,
  NavigateFn,
  NavigateOptions,
} from "@reach/router";
import {
  useDebugValue,
  useMemo,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";

export const useReachMemorySource = () => {
  useDebugValue("useReachMemorySource");

  // Using a memory router to prevent linking into deeper parts of cancel flow
  const source = useMemo(() => createMemorySource("/"), []);
  const history = useMemo(() => createHistory(source), [source]);

  /**
   * Depth functions as an index offset when backtracking to ensure we return to
   * the desired frame. Since goBack() always pushes a new frame onto the stack
   * we need to keep track of how far we need to backtrack to effectively "go back".
   */
  const [depth, setDepth] = useState<number>(1);
  const historyRef = useRef<NavigateFn>(history.navigate);

  useEffect(() => {
    history.navigate = ((
      to: string,
      options: NavigateOptions<{}> | undefined
    ) => {
      if (!to) return; // ignore empty string destinations

      // @ts-ignore
      historyRef.current(to, options);

      /**
       * For a successful navigate, the depth offset is reset back to one because we
       * only need to go back one "frame".
       */
      setDepth(1);
    }) as NavigateFn;
  }, [history]);

  const goBack = useCallback(() => {
    /**
     * navigate(-1) is currently broken in Reach's memory source. To work around
     * this we grab the path of the previous history stack entry and push that
     * onto the stack so that we're always creating new entries vs moving back
     * and forth on the stack.
     */
    historyRef.current(
      // @ts-ignore
      source.history.entries[source.history.index - depth].pathname
    );

    /**
     * Depth offset is incremented by two because we're adding to the stack but for future
     * back button presses want to reference the stack value an additional index behind.
     */
    setDepth(depth + 2);
  }, [
    depth,
    // @ts-ignore
    source.history.entries,
    // @ts-ignore
    source.history.index,
  ]);

  return {
    history,
    goBack,
  };
};
