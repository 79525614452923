import React, { useState } from "react";
import styled from "styled-components";
import {
  tsWidth,
  tsHeight,
  overlayCont,
  overlaytitle,
  overlayitem,
  overlayBtn,
  anim1,
} from "@ecomm/typography";
import { black, white, offBlack, tsbgblack1 } from "@ecomm/colors";
import { Images } from "constants/Images";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { Entry } from "contentful";
import { ICsTouchscreenProductDetailFields } from "types/contentful";

const OverlayCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  background-color: ${black};
  position: relative;
  color: ${white};
  display: block;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 10;
  position: absolute;
`;

const OverlayContainer = styled.div`
  ${overlayCont};
  background: ${offBlack};
  position: relative;
  margin: 0 auto;
`;

const OverlayImg = styled.div`
  background-color: ${black};
  img {
    /* width: 100%;
    height: auto; */
    width: 900px;
    height: 675px;
    display: block;
    ${ccIpadPortrait`
      width: 550px;
      height: auto;
      /* border: 1px dotted red; */
  `}
  }
`;

export const LoadSpinner = styled.div`
  width: 900px;
  height: 675px;
  background-color: ${tsbgblack1};
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-image: url(${Images.spinner});
  background-size: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -99999px;
`;

const OverlayDetail = styled.div``;

const Overlaytitle = styled.div`
  ${overlaytitle};
`;

const OverlayItem = styled.div`
  ${overlayitem};
`;

const OverlayClose = styled.a`
  background-image: url(${Images.globalBtnModalCloseDefault});
  ${overlayBtn};
  ${anim1};
  &:active {
    background-image: url(${Images.globalBtnModalCloseTapped});
  }
`;

interface Props {
  title: string;
  image: string | undefined;
  details?: Entry<ICsTouchscreenProductDetailFields>[] | undefined;
  hideOverlay(): void;
}

export const Overlay = (props: Props) => {
  const { title, image, details, hideOverlay } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [preloadComplete, setPreloadComplete] = useState<boolean>(false);

  if (!preloadComplete) {
    // TODO: move this to dataUtils
    const promises: Promise<string>[] = [];
    const cacheImages = (arr: string[]) => {
      arr.forEach((src) => {
        const p = new Promise<string>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(src);
          img.onerror = () => reject();
        });
        promises.push(p);
      });
      Promise.all(promises).then((results) => {
        setIsLoading(false);
        setPreloadComplete(true);
      });
    };
    if (image) cacheImages([image]);
  }

  return (
    <OverlayCanvas>
      <OverlayContainer className="doubleItem">
        {isLoading ? (
          <LoadSpinner>Loading...</LoadSpinner>
        ) : (
          <OverlayImg>
            <img alt="" src={image} />
          </OverlayImg>
        )}
        <OverlayDetail>
          <Overlaytitle>{title}</Overlaytitle>

          {details && details.map((item, i) => (
            <OverlayItem key={i}>
              <img alt="" src={item.fields.imageUrl.fields.file.url} />
              <div>{item.fields.title}</div>
            </OverlayItem>
          ))}
        </OverlayDetail>
      </OverlayContainer>
      <OverlayClose onMouseUp={() => hideOverlay()}></OverlayClose>
    </OverlayCanvas>
  );
};
