import React from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import {
  tsWidth,
  tsLogo,
  tsHeight,
  sliderDescr,
  startScreenBtn,
  anim1,
} from "@ecomm/typography";
import { tsbgblack1, white } from "@ecomm/colors";
import { Images } from "constants/Images";

const SiteMenuCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  padding-bottom: 225px;
  background-color: ${tsbgblack1};
  position: relative;
  color: ${white};
  display: block;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: absolute;
`;

const Logo = styled.div`
  /* background-image: url(${Images.globalGlowLogo}); */
  ${tsLogo};
  position: absolute;
`;

const SiteMenuTitle = styled.div`
  margin-bottom: 40px;
  ${sliderDescr};
  color: white;
`;

const SiteMenuGrid = styled.div`
  ${tsWidth};
  ${tsHeight};
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  > a {
    display: block;
    width: 300px;
    background: #181a1d;
    padding: 20px 10px;
    border-radius: 100px;
    border: 1px solid white;
    color: white;
    margin: 0 auto 20px;
    ${sliderDescr};
    &:active {
      opacity: 0.7;
    }
  }
`;

const StartScreenBtn = styled.a`
  ${startScreenBtn};
  margin-bottom: 30px;
  ${anim1};
`;

export const LocationMenu = (props: RouteComponentProps) => {
  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const navigate = props.navigate;
  return (
    <SiteMenuCanvas>
      <Logo />

      {standAlone ? (
        <SiteMenuGrid>
          <SiteMenuTitle>Choose your country</SiteMenuTitle>

          <StartScreenBtn
            onMouseUp={() =>
              navigate &&
              navigate(`/en/ipad`, {
                replace: true,
              })
            }
          >
            US
          </StartScreenBtn>

          <StartScreenBtn
            onMouseUp={() =>
              navigate &&
              navigate(`/ca/ipad`, {
                replace: true,
              })
            }
          >
            Canada
          </StartScreenBtn>
        </SiteMenuGrid>
      ) : (
        <SiteMenuGrid>
          <SiteMenuTitle>Choose your country:</SiteMenuTitle>
          <a href="/en">US</a>
          <a href="/ca">Canada</a>
        </SiteMenuGrid>
      )}
    </SiteMenuCanvas>
  );
};
