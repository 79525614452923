import React from "react";
import styled from "styled-components";
import { Images } from "constants/Images";
import { anim1 } from "@ecomm/typography/body";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";

interface SlideArrowProps {
  type: "next" | "prev";
  onMouseUp: () => void;
}

export const SlideArrow = (props: SlideArrowProps) => {
  const { type, ...rest } = props;

  return <SlideNav type={type} {...rest}></SlideNav>;
};

const SlideNav = styled.div<SlideArrowProps>`
  ${({ type }) => type === "next" && "right: 0"};
  ${({ type }) => type === "prev" && "left: 0"};
  z-index: 1000;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 754px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${({ type }) =>
    type === "next"
      ? `url(${Images.globalBtnArrowRight})`
      : `url(${Images.globalBtnArrowLeft})`};
  background-size: contain;
  background-repeat: no-repeat;
  ${anim1};
  ${ccIpadPortrait`
    top: calc(50% - 40px);
    width: 80px;
    height: 80px;
  `}
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: pointer;
    background-image: ${({ type }) =>
      type === "next"
        ? `url(${Images.globalBtnArrowRightTapped})`
        : `url(${Images.globalBtnArrowLeftTapped})`};
  }
`;
