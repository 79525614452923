import { css } from "styled-components";
import { media } from "@peloton/styles";
import { headingFamily, secondaryHeadingFamily } from "./families";
import { bold } from "./weights";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";

export const heading = css`
  ${bold}
  font-family: ${headingFamily};
  font-weight: 100;
  letter-spacing: 0.3em;
  line-height: 1.375em;
  text-transform: uppercase;
`;

export const secondaryHeading = css`
  font-family: ${secondaryHeadingFamily};
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.375em;
`;

export const hb1Hero = css`
  ${heading};
  font-size: 48px;
  line-height: 1;
  /* letter-spacing: 0.22em; */

  ${media.desktop`
    font-size: 79px;
  `}
`;

export const hb1Headline = css`
  ${heading};
  font-size: 32px;
  line-height: 1;

  ${media.tablet`
    font-size: 48px;
    line-height: 1.125em;
  `}

  ${media.desktop`
    font-size: 75px;
    letter-spacing: 8.2px;
  `}
`;

export const appHeadline = css`
  ${heading};
  font-family: "EuropaGroteskSH-MedExt", Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 70px;
  line-height: 75px;
  letter-spacing: -2.5px;
`;

export const app2Headline = css`
  font-weight: 900;
  font-style: normal;
  font-size: 70px;
  line-height: 75px;
  letter-spacing: -2.5px;
  text-transform: none;
  font-family: "EuropaGroteskSH-MedExt", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0.05rem;
  font-display: block;
`;

export const hb1 = css`
  ${heading}
  font-size: 28px;
  line-height: 1.25em;

  ${media.tablet`
    font-size: 40px;
    line-height: 1.125em;
  `}

  ${media.desktop`
    font-size: 48px;
  `}
`;

export const hb2 = css`
  ${heading}
  font-size: 22px;

  ${media.tablet`
    font-size: 28px;
    line-height: 1.25em;
  `}

  ${media.desktop`
    font-size: 32px;
  `}
`;

export const hb2nav = css`
  ${heading}

  font-size: 25px;

  ${media.tablet`
    font-size: 28px;
    line-height: 1.25em;
  `}
${media.desktopLarge`
    font-size: 32px;
    font-weight: 600;
  `}
  ${media.desktopXLarge`
    font-size: 32px;
    font-weight: 600;
  `}
`;

export const hb2Spec2 = css`
  ${heading}
  font-size: 26px;
  font-weight: 500;
  line-height: 1.3em;
  ${media.tablet`
    font-size: 28px;
    line-height: 1.25em;
  `}

  ${media.desktop`
    font-size: 32px;
  `}
`;

export const hb3 = css`
  ${heading}
  font-size: 18px;
  ${media.tablet`
    font-size: 24px;
  `}
`;

export const hb4 = css`
  ${heading}
  font-size: 18px;

  ${media.tablet`
    font-size: 20px;
  `}
`;

export const hb4hero = css`
  ${heading}
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.9px;
  ${media.desktop`
    font-size: 24px;

  `}
`;

export const hb5 = css`
  ${heading}
  font-size: 16px;
`;

export const hb6 = css`
  ${heading}
  font-size: 14px;
`;

export const hb7 = css`
  ${heading}
  font-size: 13px;
`;

export const hb8 = css`
  ${heading}
  font-size: 12px;
`;

export const hb9 = css`
  ${heading}
  font-size: 10px;

  ${media.tablet`
    font-size: 12px;
  `}
`;

export const hrsuper1 = css`
  ${secondaryHeading}
  font-size: 72px;
  font-weight: 100;

  ${media.tablet`
    font-size: 84px;
    line-height: 1.1875em;
  `}

  ${media.desktop`
    font-size: 96px;
  `}
`;

export const hr1total = css`
  ${secondaryHeading}
  font-size: 50px;

  ${media.tablet`
    font-size: 72px;
    line-height: 1.1875em;
  `}

  ${media.desktop`
    font-size: 100px;
  `}
`;

export const hr1 = css`
  ${secondaryHeading}
  font-size: 36px;

  ${media.tablet`
    font-size: 50px;
    line-height: 1.1875em;
  `}

  ${media.desktop`
    font-size: 60px;
  `}
`;

export const hr2 = css`
  ${secondaryHeading}
  font-size: 28px;

  ${media.tablet`
    font-size: 40px;
    line-height: 1.25em;
  `}
  ${media.desktop`
    font-size: 44px;
  `}
`;

export const hr3 = css`
  ${secondaryHeading}
  font-size: 24px;

  ${media.tablet`
    font-size: 32px;
  `}
  ${media.desktop`
    font-size: 36px;
  `}
`;

export const hr4 = css`
  ${secondaryHeading}
  font-size: 20px;

  ${media.tablet`
    font-size: 28px;
  `}
`;

// touch screen styles

export const startScreenTitle = css`
  ${heading};
  font-size: 70px;
  line-height: 1.4;
  letter-spacing: 0.07em;
  max-width: 640px;
  margin: 0 auto 60px;
  ${media.desktop`

  `}
  ${ccIpadPortrait`
    font-size: 22px;
  `}
`;

export const startScreenBtn = css`
  ${heading};
  display: table;
  min-width: 516px;
  padding: 34px 50px;
  border: 1px solid rgba((255, 51, 71, 1));
  border-radius: 100px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-family: "EuropaGroteskSH-MedExt", Arial, sans-serif;
  line-height: 1em;
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 6px;
  &:active {
    /* background-color: rgba((255, 51, 71, 0.3)); */
    opacity: 0.5;
  }
  ${media.desktop`

  `}
`;

export const startScreenBtn2 = css`
  display: block;
  width: 444px;
  height: 110px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin: 0 auto;
  text-indent: -999999px;
  ${media.desktop`

  `}
`;

export const AccessoriesText = css`
  ${heading};
  font-size: 28px;
  line-height: 1.3;
  letter-spacing: 0.07em;
  ${media.desktop`

  `}
`;

export const packageTitle1 = css`
  ${heading};
  font-size: 36px;
  line-height: 1.3;
  letter-spacing: 0.1em;
  ${media.desktop`

  `}
  ${ccIpadPortrait`
    font-size: 22px;
  `}
`;

export const packagePrice1 = css`
  ${heading};
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.08em;
  ${media.desktop`

  `}

  ${ccIpadPortrait`
    font-size: 18px;
  `}
`;

export const packageFooter = css`
  ${heading};
  font-size: 28px;
  line-height: 1.3;
  letter-spacing: 0.08em;
  ${media.desktop`

  `}

  ${ccIpadPortrait`
    font-size: 18px;
  `}
`;

export const overlaytitle = css`
  padding: 86px 40px 40px;
  text-align: center;
  ${heading};
  font-size: 28px;
  line-height: 1;
  color: #df1c2f;
  letter-spacing: 0.08em;

  ${ccIpadPortrait`
    font-size: 18px;
    padding: 25px 20px 20px;
  `}
`;

export const overlayBtn = css`
  display: block;
  width: 322px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin: 0 auto;
  position: absolute;
  bottom: 150px;
  left: 380px;
  ${media.desktop`

  `}

  ${ccIpadPortrait`
    width: 193px;
    height: 80px;
    bottom: 50px;
    left: calc(50% - 97px);
  `}
`;

export const sliderBigTitle = css`
  ${heading};
  font-size: 160px;
  line-height: 1;
  letter-spacing: 0.06em;
  text-align: center;
  opacity: 0.25;
  color: #697180;

  ${media.desktop`

  `}

  ${ccIpadPortrait`
    font-size: 80px;
  `}
`;

export const sliderNeedTitle = css`
  text-align: center;
  ${heading};
  font-size: 28px;
  line-height: 1;
  color: #ff4041;
  letter-spacing: 0.08em;

  ${ccIpadPortrait`
    font-size: 18px;
  `}
`;

export const sliderDescr = css`
  text-align: center;
  font-size: 34px;
  line-height: 1.4em;
  color: #a7acb1;

  ${ccIpadPortrait`
      font-size: 16px;
  `}
`;

export const sliderMainTitle = css`
  text-align: center;
  ${heading};
  /* font-size: 70px; */
  font-size: 76px;
  line-height: 1;
  letter-spacing: 0.1em;

  ${ccIpadPortrait`
    font-size: 32px;
  `}
`;

export const sliderMainPrice = css`
  text-align: center;
  ${heading};
  font-size: 34px;
  line-height: 1;
  letter-spacing: 0.1em;
  ${ccIpadPortrait`
    font-size: 16px;
  `}
`;

export const sliderMainFinance = css`
  text-align: center;
  font-size: 22px;
  line-height: 1.4em;
  color: #878b93;

  ${ccIpadPortrait`
    font-size: 12px;
  `}
`;

export const SliderChicTit = css`
  text-align: center;
  ${heading};
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.08em;
`;

export const sliderListTitle = css`
  ${heading};
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.08em;

  ${ccIpadPortrait`
    font-size: 18px;
  `}
`;

export const sliderListTxt = css`
  font-size: 34px;
  line-height: 1.4em;
  color: #878b93;

  ${ccIpadPortrait`
    font-size: 16px;
  `}
`;
