import React from "react";
import styled from "styled-components";
import { Images } from "constants/Images";
import { anim1 } from "@ecomm/typography/body";
import { Offset } from "types/common";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";

interface SlideViewDetailsProps {
  onMouseUp: () => void;
  left: number;
  top: number;
}

export const SlideViewDetails = (props: SlideViewDetailsProps) => {
  const { ...rest } = props;
  return <ViewDetails {...rest}></ViewDetails>;
};

const ViewDetails = styled.div<Offset>`
  z-index: 1;
  width: 224px;
  height: 90px;
  position: absolute;
  ${({ top }) => top && `top: ${top}px`};
  ${({ left }) => left && `left: ${left}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  ${anim1};

  background-image: url(${Images.globalBtnDetailDefault});
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: pointer;
    background-image: url(${Images.globalBtnDetailTapped});
  }

  ${ccIpadPortrait`
    width: 180px;
    height: 72px;
    left: 20vw !important;
    top: 50vh !important;
  `}
`;
