import { css } from "styled-components";
import { media } from "@peloton/styles";
import { bodyFamily } from "./families";
import { light } from "./weights";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";

export const body = css`
  ${light}
  font-family: ${bodyFamily};
  letter-spacing: 0.025em;
  line-height: 1.5em;
`;

export const b1 = css`
  ${body}
  font-size: 18px;

  ${media.tablet`
    font-size: 24px;
  `}
`;

export const b2 = css`
  ${body}
  font-size: 16px;

  ${media.tablet`
    font-size: 18px;
  `}
`;

export const b3 = css`
  ${body}
  font-size: 14px;

  ${media.tablet`
    font-size: 16px;
    letter-spacing: 0.4px;
  `}
`;

export const b4 = css`
  ${body}
  font-size: 14px;
`;

export const b5 = css`
  ${body}
  font-size: 13px;
`;

export const b6 = css`
  ${body}
  font-size: 12px;
`;

export const b6Grow = css`
  ${body}
  font-size: 12px;

  ${media.tablet`
    font-size: 16px;
  `}
`;

export const b7 = css`
  ${body}
  font-size: 10px;
`;

// touch screen styles

export const anim1 = css`
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  transition: all 250ms linear;
`;

export const touchPress1 = css`
  ${anim1};
  &:active {
    opacity: 0.8;
  }
`;

export const tsWidth = css`
  width: 1080px;
  ${ccIpadPortrait`
    width: auto;
    min-width: 100vw;
    max-width: 100vw;
    > div {
      // border: 1px solid blue;
    }
  `}
`;

export const tsHeight = css`
  height: 1920px;
  ${ccIpadPortrait`
    height: auto;
    min-height: 100vh;
  `}
`;

export const tsLogo = css`
  width: 44px;
  height: 60px;
  display: block;
  position: absolute;
  background-size: 44px 60px;
  top: 80px;
  left: calc(50% - 22px);
  display: none;

  ${ccIpadPortrait`
    width: 22px;
    height: 30px;
    background-size: contain;
    top: 40px;
    left: calc(50% - 11px);
  `}
`;

export const tsMainNavHeight = css`
  height: 120px;
  ${ccIpadPortrait`
    height: 60px;
  `}
`;

export const tsMainBlocks = css`
  width: 900px;
  height: 480px;
  ${touchPress1};
  ${ccIpadPortrait`
    width: 560px;
    height: 300px;
    // border: 1px solid red;
  `}
`;

export const overlayCont = css`
  width: 900px;
  height: auto;
  padding-bottom: 80px;
  border-radius: 5px;
  ${ccIpadPortrait`
    width: 550px;
  `}
`;

export const featureOverlayitem = css`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;

  ${ccIpadPortrait`
    width: 450px;
    padding: 15px 0;
  `}
`;

export const overlayitem = css`
  width: 700px;
  height: auto;
  margin: 0 auto;
  padding: 40px 0;
  ${ccIpadPortrait`
    width: 450px;
    padding: 15px 0;
  `}
  img {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    height: 100px;
    ${ccIpadPortrait`
      width: 50px;
      height: 50px;
  `}
  }
  > div {
    display: inline-block;
    vertical-align: middle;
    font-size: 34px;
    width: 590px;
    padding-left: 50px;
    color: #888b93;
    line-height: 1.4em;
    ${ccIpadPortrait`
      font-size: 16px;
      width: 400px;
      padding-left: 20px;
  `}
  }
`;

export const AppTableCell = css`
  /* min-height: 278px; */
  font-size: 22px;
  line-height: 1.6em;
  font-weight: 400;
  padding: 20px;
  background-color: #181b1e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  > span {
    display: block;
    padding-top: 20px;
    color: #8a8d91;
    line-height: 28px;
    min-height: 56px;
  }
  > span img {
    width: 28px;
    height: auto;
  }
  ${ccIpadPortrait`

  `}
`;
