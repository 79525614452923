/*eslint @typescript-eslint/no-explicit-any: "off"*/
import { css, ThemedCssFunction } from 'styled-components';

/**
 * This is a tagged template literal for targeting IE10 and IE11 browsers with specifric CSS.
 * It expects a valid CSS expression and will output that expression wrapped in a media query
 * that targets only IE10 and IE11 browsers but not Edge
 *
 * Media query supplied by: https://gist.github.com/zunairmushtaq/aeaa48432d51cad0eb1c
 *
 * Usage:
 * ie11`
 *   property: value;
 *   property: value;
 * `
 *
 * @param args The template literal
 */
export const ie11: ThemedCssFunction<any> = (...args: any[]) => css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${(css as any)(...args)}
  }
`;

export const msEdge: ThemedCssFunction<any> = (...args: any[]) => css`
  @supports (-ms-ime-align: auto) {
    ${(css as any)(...args)}
  }
`;

export const ieHideScroll = css`
  ${ie11`
    -ms-overflow-style: -ms-autohiding-scrollbar;
  `}
  ${msEdge`
    -ms-overflow-style: -ms-autohiding-scrollbar;
  `}
`;
