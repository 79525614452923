import React, { useState } from "react";
import styled from "styled-components";
import {
  tsWidth,
  tsHeight,
  overlayCont,
  overlaytitle,
  overlayBtn,
  anim1,
} from "@ecomm/typography";
import { black, white, offBlack, tsbgblack1 } from "@ecomm/colors";
import { Images } from "constants/Images";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";

const OverlayCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  background-color: ${black};
  position: relative;
  color: ${white};
  display: block;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  position: fixed;
  top: 0px;
  left: 0px;
`;

const OverlayContainer = styled.div`
  ${overlayCont};
  background: ${offBlack};
  position: relative;
  margin: 0 auto;
  border-radius: 0px;
  padding-bottom: 0px;
`;

const OverlayImg = styled.div`
  background-color: ${black};
  img {
    /* width: 100%;
    height: auto; */
    width: 100%;
    height: auto;
    display: block;
    ${ccIpadPortrait`
      width: 550px;
      height: auto;
      /* border: 1px dotted red; */
  `}
  }
`;

export const LoadSpinner = styled.div`
  width: 900px;
  height: 675px;
  background-color: ${tsbgblack1};
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-image: url(${Images.spinner});
  background-size: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -99999px;
`;

const OverlayCopy = styled.div`
  padding: 0 60px 110px 60px;
  font-size: 34px;
  line-height: 1.5em;
  text-align: center;
`;

const OverlayDetail = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 1.6em;
  }
  table {
    margin: auto;
    padding-top: 20px;
  }
  th {
    font-size: 60px;
    font-weight: 300;
    padding: 0 20px;
    text-align: center;
  }
  td {
    font-size: 22px;
    padding: 0 20px;
    text-align: center;
  }
`;

const Overlaytitle = styled.div`
  ${overlaytitle};
  color: #cdff38;
  padding-bottom: 58px;
`;

const OverlayClose = styled.a`
  background-image: url(${Images.globalBtnModalCloseDefault});
  ${overlayBtn};
  ${anim1};
  &:active {
    background-image: url(${Images.globalBtnModalCloseTapped});
  }
`;

interface Props {
  title: string;
  image: string | undefined;
  details: string | undefined;
  hideOverlay(): void;
}

interface CustomComponentProps {
  details: string | undefined;
}

export const CustomComponent: React.FC<CustomComponentProps> = ({
  details,
}) => {
  let customHTML;
  switch (details) {
    case "yinYoga":
      customHTML = (
        <div>
          <p>Program &nbsp;&#8226;&nbsp; 1 Week</p>
          <p>
            In collaboration with the Christopher & Dana Reeve Foundation, Logan
            invites you to discover your physical & mental strength in this
            program for wheelchair users living with spinal cord injury or other
            causes of paralysis.
          </p>
          <table>
            <tbody>
              <tr>
                <th>1</th>
                <th>6</th>
                <th>10</th>
                <th>10-25</th>
              </tr>
              <tr>
                <td>week</td>
                <td>days</td>
                <td>classes</td>
                <td>mins per day</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
      break;
    case "dancecardio":
      customHTML = (
        <div>
          <p>Collection &nbsp;&#8226;&nbsp; 26 Classes</p>
          <p>
            Break it down and break a sweat with Dance Cardio&mdash;featuring
            heart&ndash;pumping classes with instructors and dance superstars
            alike&mdash;on demand now. No equipment or experience necessary.
          </p>
        </div>
      );
      break;
    case "mobility":
      customHTML = (
        <div>
          <p>Collection &nbsp;&#8226;&nbsp; 27 Classes</p>
          <p>
            This specialized series is designed to help you increase your joint
            and muscle range of motion, as well as improve posture and overall
            body awareness—perfect if you’re traveling.
          </p>
        </div>
      );
      break;
    case "selfcare":
      customHTML = (
        <div>
          <p>Program &nbsp;&#8226;&nbsp; 1 Week</p>
          <p>
            Join Anna on a self-care retreat from the comfort of your own home!
          </p>
          <table>
            <tbody>
              <tr>
                <th>1</th>
                <th>6</th>
                <th>8</th>
                <th>10-35</th>
              </tr>
              <tr>
                <td>week</td>
                <td>days</td>
                <td>classes</td>
                <td>mins per day</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
      break;
    case "boxing":
      customHTML = (
        <div>
          <p>Collection &nbsp;&#8226;&nbsp; 189 Classes</p>
          <p>
            Boxing is finally here. Discover a fast, furious and fun workout
            that will test you physically and mentally.
          </p>
        </div>
      );
      break;
    case "density":
      customHTML = (
        <div>
          <p>Collection &nbsp;&#8226;&nbsp; 16 Classes</p>
          <p>
            Welcome to Total Strength: Density Training. This 4-week program is
            designed to build muscle through fast-paced full-body workouts that
            will push you to your limits. Peloton, get ready to rep it out.
          </p>
        </div>
      );
      break;
    case "adaptive":
      customHTML = (
        <div>
          <p>Program &nbsp;&#8226;&nbsp; 1 Week</p>
          <p>
            In collaboration with the Christopher & Dana Reeve Foundation, Logan
            invites you to discover your physical & mental strength in this
            program for wheelchair users living with spinal cord injury or other
            causes of paralysis.
          </p>
          <table>
            <tbody>
              <tr>
                <th>1</th>
                <th>6</th>
                <th>10</th>
                <th>10-25</th>
              </tr>
              <tr>
                <td>week</td>
                <td>days</td>
                <td>classes</td>
                <td>mins per day</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
      break;
    // Add more cases for other keys as needed
    default:
      customHTML = <div>Unknown key</div>;
  }

  return customHTML;
};

export const AppOverlay = (props: Props) => {
  const { title, image, details, hideOverlay } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [preloadComplete, setPreloadComplete] = useState<boolean>(false);

  if (!preloadComplete) {
    // TODO: move this to dataUtils
    const promises: Promise<string>[] = [];
    const cacheImages = (arr: string[]) => {
      arr.forEach((src) => {
        const p = new Promise<string>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(src);
          img.onerror = () => reject();
        });
        promises.push(p);
      });
      Promise.all(promises).then((results) => {
        setIsLoading(false);
        setPreloadComplete(true);
      });
    };
    if (image) cacheImages([image]);
  }

  return (
    <OverlayCanvas>
      <OverlayContainer className="doubleItem">
        {isLoading ? (
          <LoadSpinner>Loading...</LoadSpinner>
        ) : (
          <OverlayImg>
            <img alt="" src={image} />
          </OverlayImg>
        )}
        <OverlayDetail>
          <Overlaytitle>{title}</Overlaytitle>
          <OverlayCopy>
            <CustomComponent details={details} />
          </OverlayCopy>
        </OverlayDetail>
      </OverlayContainer>
      <OverlayClose onMouseUp={() => hideOverlay()}></OverlayClose>
    </OverlayCanvas>
  );
};
