import { default as bikeData } from "data/bike_packs.json";
import { default as treadData } from "data/tread_packs.json";
import { default as treadDataCA } from "data/tread_packs_ca.json";
import { default as yogaData } from "data/yoga_packs.json";
import { default as strengthData } from "data/strength_packs.json";
import { default as alacarteData } from "data/accessories.json";
import { PackageCategories } from "types/package";

export const isLocal = () => window.location.hostname.includes("localhost");
export const isStaging = () => window.location.hostname.includes("uat");

export const getData = (type: string, location?: string) => {
  let data;
  switch (type) {
    case "bike":
      data = (bikeData as unknown) as PackageCategories;
      break;
    case "tread":
      if (location === "en") {
        data = (treadData as unknown) as PackageCategories;
      } else {
        data = (treadDataCA as unknown) as PackageCategories;
      }

      break;
    case "yoga":
      data = (yogaData as unknown) as PackageCategories;
      break;
    case "strength":
      data = (strengthData as unknown) as PackageCategories;
      break;
    default:
      data = (bikeData as unknown) as PackageCategories;
  }
  return data;
};

export const getAccessoriesData = () => {
  return alacarteData;
};
