import React from 'react';
import styled from 'styled-components';
import {
  sliderListTitle,
  sliderListTxt,
  tsWidth,
  tsHeight,
} from '@ecomm/typography';
import { AccessoriesItem } from 'types/accessories';

const SlideListCanvas = styled.div`
  position: relative;
  ${tsWidth};
  ${tsHeight};
  display: grid;

  align-content: center;
  justify-content: center;
  /* background-color: #060607; */
`;

const SlideListContainer = styled.div`
  width: 540px;
  margin-top: -120px;
  /* margin: auto;
  padding-top: 406px; */
  ul {
    padding: 0;
    margin: 0;
  }
`;

const SlideListTitle = styled.div`
  ${sliderListTitle};
  color: #e82c27;
  text-align: center;
  border-bottom: 1px solid #363839;
  padding-bottom: 50px;
`;

const ListItem = styled.li`
  ${sliderListTxt};
  border-bottom: 1px solid #363839;
  list-style: none;
  padding: 18px 10px 18px 40px;
  p {
    margin: 0;
    position: relative;
  }
  p:before {
    content: '•';
    position: absolute;
    left: -25px;
    top: -2px;
    font-size: 26px;
  }
`;

interface Props {
  items: AccessoriesItem[];
  listOnlyItems?: string[] | undefined;
}

export const PackageSlideList = (props: Props) => {
  const { items, listOnlyItems } = props;
  const showQuantity = (count?: number, multipleStr?:string) => {

    const maybePluralize = (count: number, noun: string, suffix = 's') =>
      `${count} ${noun}${count !== 1 ? suffix : ''}`;

    if (multipleStr && count) {
      return `(${multipleStr && maybePluralize(count, multipleStr)})`;
    }
    if (count && count > 1) return `(${count})`;

    return null;
  };

  return (
    <div>
      <SlideListCanvas>
        <SlideListContainer>
          <SlideListTitle>Included in this Package</SlideListTitle>
          <ul>
            {items.map((item, i) => (
              <ListItem key={i}>
                <p>
                  {item.title}{' '}
                  {showQuantity(item.count, item.multipleStr)}
                </p>
              </ListItem>
            ))}
            {listOnlyItems?.map((listOnlyItem, i) => (
              <ListItem key={i + items.length}>
                <p>{listOnlyItem}</p>
              </ListItem>
            ))}
          </ul>
        </SlideListContainer>
      </SlideListCanvas>
    </div>
  );
};
