import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import { MainCanvas } from "components/UI/MainCanvas";
import styled from "styled-components";
import { tsWidth, tsHeight, SliderChicTit } from "@ecomm/typography";
import { Images } from "constants/Images";
import { AccessoriesSlides } from "components/AccessoriesSlides";
import { Navigation } from "components/Navigation";
import { Api } from "api/Api";
import { Entry } from "contentful";
import { ICsTouchscreenProductFields } from "types/contentful";
import { useManualQuery } from "graphql-hooks";
import { AccessoriesItem } from "types/accessories";

const SlideGlow = styled.div`
  position: absolute;
  ${tsWidth};
  ${tsHeight};
  background-color: #060607;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
`;

// const AccTitle = styled.div`
//   color: #cdff38;
//   text-transform: uppercase;
//   font-size: 30px;
//   font-family: "EuropaGroteskSH-MedExt";
// `;

const SlideChicTitle = styled.div`
  position: absolute;
  ${tsWidth};
  ${SliderChicTit};
  color: #878b93;
  top: 100px;
  color: white;
  font-size: 28px;
  left: 0px;
  text-align: center;
`;

export const Accessories = (props: RouteComponentProps) => {
  const location = useLocation();
  const locationArray = location.pathname.split("/");

  const intlLocation = locationArray[1];
  let catalogSlug = "dotcom";
  let catalogLocale = "EN_US";
  let catalogCurrency = "USD";
  let apiLocale = "en-US";
  if (intlLocation === "ca") {
    catalogSlug = "ca_webstore";
    catalogLocale = "EN_CA";
    catalogCurrency = "CAD";
    apiLocale = "en-CA";
  }
  const curSection = locationArray[2];
  const [products, setProducts] = useState<
    Entry<ICsTouchscreenProductFields>[] | null
  >(null);
  const [graphData, setGraphData] = useState<any | null>(null);
  const [slideData, setSlideData] = useState<AccessoriesItem[] | null>(null);

  const ACCESSORIES_BY_TYPE_QUERY = `{
    catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
      accessories(equipmentType: ${curSection.toUpperCase()}) {
        description
        slug
        price {
          amount
          currency
        }
        variants {
          price {
            amount
          }
        }
      }
    }
  }`;

  const ACCESSORIES_BY_SLUG_QUERY = `query getProductBySlug($productSlug:Slug!) {
    catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
      productBySlug(slug: $productSlug) {
        description
        slug
        id
        price {
          amount
          currency
        }
        variants {
          price {
            amount
          }
        }
      }
    }
  }`;

  const [fetchAccessoriesByType] = useManualQuery(ACCESSORIES_BY_TYPE_QUERY);
  const [fetchAccessoriesBySlug] = useManualQuery(ACCESSORIES_BY_SLUG_QUERY);

  useEffect(() => {
    const fetchData = async () => {
      if (
        curSection !== "yoga" &&
        curSection !== "strength" &&
        curSection !== "row"
      ) {
        const adata = await fetchAccessoriesByType();
        setGraphData(adata.data.catalog.accessories);
      } else {
        if (products) {
          Promise.all(
            products.map(async (product) => {
              const p = await fetchAccessoriesBySlug({
                variables: { productSlug: product.fields.slug },
              });
              return p;
            })
          ).then((response) => {
            const adata = response.map(
              (r) => !r.error && r.data.catalog.productBySlug
            );
            setGraphData(adata);
          });
        }
      }
    };
    if (products && !graphData) fetchData();
  }, [
    products,
    graphData,
    curSection,
    fetchAccessoriesByType,
    fetchAccessoriesBySlug,
  ]);

  useEffect(() => {
    const fetchProductData = async () => {
      Api.getProducts(apiLocale).then((response) => {
        const products: Entry<ICsTouchscreenProductFields>[] = response.items;
        const screenTypeProducts: Entry<
          ICsTouchscreenProductFields
        >[] = products.reduce(
          (acc: any, obj: Entry<ICsTouchscreenProductFields>) => {
            obj.fields.screenType &&
              // eslint-disable-next-line array-callback-return
              obj.fields.screenType.find((o) => {
                if (o.fields.path === curSection) {
                  acc.push(obj);
                }
              });
            return acc;
          },
          []
        );
        setProducts(screenTypeProducts);
      });
    };
    fetchProductData();
  }, [apiLocale, curSection]);

  const getPrice = (variants: any[]) => {
    if (
      variants.length > 1 &&
      variants[0].price.amount !== variants[variants.length - 1].price.amount
    ) {
      return `${variants[0].price.amount / 100} - $${
        variants[variants.length - 1].price.amount / 100
      }`;
    } else {
      return `${variants[0].price.amount / 100}`;
    }
  };

  useEffect(() => {
    const combineData = () => {
      // console.log("graphData", graphData)
      const getProductData = (slug: string) => {
        return products?.find((product) => product.fields.slug === slug);
      };
      const accessories = graphData.reduce((a: AccessoriesItem[], p: any) => {
        const productData = getProductData(p.slug);
        if (productData) {
          const item: AccessoriesItem = {
            slug: productData.fields.slug,
            title: productData.fields.name,
            description: productData.fields.description,
            slideUrl: productData.fields.slideUrl?.fields.file.url,
            imageUrl: productData.fields.imageUrl?.fields.file.url,
            iconUrl: productData.fields.iconUrl?.fields.file.url,
            iconUrlActive: productData.fields.iconUrlActive?.fields.file.url,
            price: getPrice(p.variants), //p.price.amount/100,
            detailOffsetX: productData.fields.detailOffsetX,
            detailOffsetY: productData.fields.detailOffsetY,
            id: productData.fields.id,
            details: productData.fields.details
              ? productData.fields.details
              : [],
            multipleStr: productData.fields.multipleStr,
            alacarteUrl: productData.fields.alacarteUrl?.fields.file.url || "",
          };
          a.push(item);
        }

        return a;
      }, []);
      accessories.sort((a: AccessoriesItem, b: AccessoriesItem) => a.id - b.id);
      setSlideData(accessories);
    };
    if (graphData && products) combineData();
  }, [graphData, products]);

  return (
    <MainCanvas>
      <SlideGlow></SlideGlow>
      {/* <AccTitle>Peloton &nbsp; Accessories</AccTitle> */}
      {slideData && <AccessoriesSlides data={slideData}></AccessoriesSlides>}
      <SlideChicTitle>Accessories a la carte</SlideChicTitle>
      <Navigation></Navigation>
    </MainCanvas>
  );
};
