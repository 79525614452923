import { css } from 'styled-components';
import { hover } from './hover';

export const defaultTransition = (
  properties = 'all',
  duration = 250,
  timingFunction = 'ease',
) => css`
  transition-property: ${properties};
  transition-duration: ${duration}ms;
  transition-timing-function: ${timingFunction};
`;

export type Transition = { property: string; from?: string; to: string };
export const hoverTransition = (...transitions: Transition[]) => css`
  ${defaultTransition(
    transitions.map((transition) => transition.property).join(', '),
  )}
  ${transitions
    .filter(hasFromProperty)
    .reduce(splitMultipleProperties, [])
    .map(
      (transition) => css`
        ${transition.property}: ${transition.from as string};
      `,
    )}
  ${hover`
    ${transitions.reduce(splitMultipleProperties, []).map(
      (transition) => css`
        ${transition.property}: ${transition.to};
      `,
    )}
  `}
`;

const hasFromProperty = (transition: Transition) => !!transition.from;

const splitMultipleProperties = (
  aggregator: Transition[],
  transition: Transition,
) =>
  aggregator.concat(
    transition.property.split(',').map((property) => ({
      property,
      from: transition.from,
      to: transition.to,
    })),
  );
