import { css } from 'styled-components';
import { transparentize } from 'polished';
import { hoverTransition } from '@peloton/styles';
import {
  lightGray,
  mediumGray,
  offBlack,
  slate,
  superRed,
  white,
} from '@ecomm/colors';

const link = css`
  cursor: pointer;
`;

export const l1 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: superRed,
    to: offBlack,
  })}
`;

export const l2 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: offBlack,
    to: superRed,
  })}
`;

export const l3 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: lightGray,
    to: offBlack,
  })}
`;

export const l4 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: white,
    to: transparentize(0.4, white),
  })}
`;

export const l5 = css`
  ${link}
  color: ${offBlack};
  padding-bottom: 1px;
  border-bottom: solid 1px ${mediumGray};

  ${hoverTransition({
    property: 'color, fill, border-color',
    to: superRed,
  })};
`;

export const l6 = css`
  ${link}
  color: ${slate};
  padding-bottom: 1px;
  border-bottom: solid 1px ${slate};

  ${hoverTransition({
    property: 'color, fill, border-color',
    to: offBlack,
  })};
`;
