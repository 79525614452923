import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Navigation } from "components/Navigation";
import { MainCanvas } from "components/UI/MainCanvas";

import { Images } from "constants/Images";
import styled from "styled-components";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { app2Headline } from "@ecomm/typography";
import { AppOverlay } from "../components/AppOverlay";
import { animated, useSpring } from "react-spring";

const TabBack = styled.a`
  display: none;

  ${ccIpadPortrait`
    position: absolute;
    left: 122px;
    top: 48px;
    z-index: 20;
    color: #888b93;
    display: block;
    font-weight: 700;
    font-family: "EuropaGroteskSH-MedExt", Arial, sans-serif;
    -webkit-letter-spacing: 0.1em;
    -moz-letter-spacing: 0.1em;
    -ms-letter-spacing: 0.1em;
    letter-spacing: 0.1em;
    line-height: 1.375em;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.3;
    background-size: 10px;
    padding-left: 26px;
    background-image: url(${Images.globalBtnNavBackBtn});
    background-position: left center;
    background-repeat: no-repeat;
    /* test */
  `}
`;

const AppSubcontainer = styled.div`
  display: block;
  position: relative;
  padding: 83px 0px 150px 90px;
  background: #181a1d;
  min-height: 100vh;
`;

const AppTitle = styled.div`
  color: #cdff38;
  text-transform: uppercase;
  font-size: 30px;
  font-family: "EuropaGroteskSH-MedExt";
  position: absolute;
  left: 93px;
  top: 80px;
`;

const AppSubtitle = styled.div`
  padding-top: 50px;
  display: block;
  position: relative;
  margin-bottom: 67px;
  ${app2Headline}
`;

const AppTile = styled.div`
  display: block;
  position: relative;
  width: 900px;
  /* height: 406px; */
  margin-bottom: 46px;
  img {
    width: 900px;
    height: auto;
  }
`;

const OverlayAnim = styled(animated.div)`
  position: relative;
  z-index: 1001;
`;

export const Programs = (props: RouteComponentProps) => {
  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayImage, setOverlayImage] = useState(Images.app3ontgYinYoga); // Images.app3ontgYinYoga
  const [overlayTitle, setOverlayTitle] = useState("Yin Yoga"); // Images.app3ontgYinYoga
  const [overlayDetails, setOverlayDetails] = useState("yinYoga"); // Images.app3ontgYinYoga
  const { navigate } = props;

  const intlLocation = props.location.pathname.split("/")[1];

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };
  const overlayAnimSpring = useSpring({
    opacity: showOverlay ? 1 : 0,
  });

  return (
    <MainCanvas>
      {standAlone && (
        <TabBack
          onMouseUp={() =>
            navigate && navigate(`/${intlLocation}/ipad`, { replace: true })
          }
        >
          Home
        </TabBack>
      )}
      <OverlayAnim style={overlayAnimSpring}>
        {showOverlay && (
          <AppOverlay
            title={overlayTitle}
            details={overlayDetails}
            image={overlayImage}
            hideOverlay={toggleOverlay}
          ></AppOverlay>
        )}
      </OverlayAnim>
      <AppSubcontainer>
        <AppTitle>Peloton &nbsp; App</AppTitle>
        <AppSubtitle>
          Workouts that go
          <br />
          where you go
        </AppSubtitle>
        <AppTile>
          <div
            role="link"
            tabIndex={0}
            onClick={() => {
              setOverlayImage(Images.app3ontgYinYogaDetail);
              setOverlayTitle("Yin Yoga");
              setOverlayDetails("yinYoga");
              toggleOverlay();
            }}
            aria-label="Click to view Yin Yoga details"
          >
            <img alt="" src={Images.app3ontgYinYoga} />
          </div>
        </AppTile>
        <AppTile>
          <div
            role="link"
            tabIndex={1}
            onClick={() => {
              setOverlayImage(Images.app3ontgDanceCardioDetail);
              setOverlayTitle("Dance Cardio");
              setOverlayDetails("dancecardio");
              toggleOverlay();
            }}
            aria-label="Click to view Dance Cardio details"
          >
            <img alt="" src={Images.app3ontgDanceCardio} />
          </div>
        </AppTile>
        <AppTile>
          <div
            role="link"
            tabIndex={1}
            onClick={() => {
              setOverlayImage(Images.app3ontgMobilityDetail);
              setOverlayTitle("Mobility");
              setOverlayDetails("mobility");
              toggleOverlay();
            }}
            aria-label="Click to view Mobility details"
          >
            <img alt="" src={Images.app3ontgMobility} />
          </div>
        </AppTile>
        <AppTile>
          <div
            role="link"
            tabIndex={1}
            onClick={() => {
              setOverlayImage(Images.app3ontgSelfCareDetail);
              setOverlayTitle("Self Care Retreat");
              setOverlayDetails("selfcare");
              toggleOverlay();
            }}
            aria-label="Click to view Self Care details"
          >
            <img alt="" src={Images.app3ontgSelfCare} />
          </div>
        </AppTile>
        <AppTile>
          <div
            role="link"
            tabIndex={1}
            onClick={() => {
              setOverlayImage(Images.app3ontgBoxingDetail);
              setOverlayTitle("Peloton Boxing");
              setOverlayDetails("boxing");
              toggleOverlay();
            }}
            aria-label="Click to view Meditation boot details"
          >
            <img alt="" src={Images.app3ontgBoxing} />
          </div>
        </AppTile>
        <AppTile>
          <div
            role="link"
            tabIndex={1}
            onClick={() => {
              setOverlayImage(Images.app3ontgDensityDetail);
              setOverlayTitle("TOTAL STRENGTH:  DENSITY TRAINING");
              setOverlayDetails("density");
              toggleOverlay();
            }}
            aria-label="Click to view core details"
          >
            <img alt="" src={Images.app3ontgDensity} />
          </div>
        </AppTile>
        <AppTile>
          <div
            role="link"
            tabIndex={1}
            onClick={() => {
              setOverlayImage(Images.app3ontgAdaptedDetail);
              setOverlayTitle("SEATED ADAPTIVE STRENGTH");
              setOverlayDetails("adaptive");
              toggleOverlay();
            }}
            aria-label="Click to view core details"
          >
            <img alt="" src={Images.app3ontgAdapted} />
          </div>
        </AppTile>
      </AppSubcontainer>
      <Navigation></Navigation>
      <a href="/" className="hidden-btn">
        &nbsp;&nbsp;
      </a>
    </MainCanvas>
  );
};
