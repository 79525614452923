/*eslint @typescript-eslint/no-explicit-any: "off"*/

import { css, ThemedCssFunction } from "styled-components";

export const BreakpointWidth = {
  iPhone6: 375,
  smallScreen: 380,
  tablet: 580,
  tabletLarge: 640,
  tabletXLarge: 768,
  desktop: 800,
  desktopLarge: 1024,
  desktopXLarge: 1280,
  infinity: 1920,
};

type BreakpointCssMap = Record<
  keyof typeof BreakpointWidth,
  ThemedCssFunction<any>
>;

export const media: BreakpointCssMap = Object.keys(BreakpointWidth).reduce(
  (mediaQueries, label) => ({
    ...mediaQueries,
    [label]: (...args: any[]) => css`
      @media (min-width: ${BreakpointWidth[
          label as keyof typeof BreakpointWidth
        ]}px) {
        ${(css as any)(...args)}
      }
    `,
  }),
  {} as BreakpointCssMap
);

export const mobileLandscape = (...args: any[]) => css`
  @media only screen and (min-width: 320px) and (max-width: 824px) and (orientation: landscape) {
    ${(css as any)(...args)}
  }
`;

export const ccIpadPortrait = (...args: any[]) => css`
  @media only screen and (min-width: 0px) and (max-width: 1024px) and (orientation: portrait) {
    ${(css as any)(...args)}
  }
`;
