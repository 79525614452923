import { createClient } from "contentful";
import {
  ICsTouchscreenProductFields,
  ICsTouchscreenPackageFields,
  ICsTouchscreenEquipmentTypeFields,
  ICsTouchscreenStringsFields,
  ICsTouchscreenScreenTypeFields,
} from "types/contentful";

const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_ACCESSTOKEN || "",
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || "",
  environment: "concept-store", //process.env.REACT_APP_CONTENTUL_ENVIRONMENT || '',
});

const getStrings = async () => {
  const [stringsResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenStringsFields>({
      content_type: "csTouchscreenStrings",
      order: "-sys.updatedAt",
    }),
  ]);
  return stringsResponse;
};

const getProducts = async (locale: string = "en-US") => {
  const [productsResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenProductFields>({
      content_type: "csTouchscreenProduct",
      order: "fields.id",
      locale: locale,
    }),
  ]);
  return productsResponse;
};

const getPackageBySlug = async (slug: string, locale: string = "en-US") => {
  const [packagesResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenPackageFields>({
      content_type: "csTouchscreenPackage",
      "fields.slug": slug,
      order: "fields.id",
      locale: locale,
    }),
  ]);
  return packagesResponse;
};

const getPackagesByEquipmentType = async (
  equipmentType: string,
  locale: string = "en-US"
) => {
  const [packagesResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenPackageFields>({
      content_type: "csTouchscreenPackage",
      "fields.equipmentType.sys.contentType.sys.id":
        "csTouchscreenEquipmentType",
      "fields.equipmentType.fields.enum": equipmentType,
      order: "fields.id",
      locale: locale,
    }),
  ]);
  return packagesResponse;
};

const getPackagesByEquipmentTypes = async (equipmentTypes: string) => {
  const [packagesResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenPackageFields>({
      content_type: "csTouchscreenPackage",
      "fields.equipmentType.sys.contentType.sys.id":
        "csTouchscreenEquipmentType",
      "fields.equipmentType.fields.enum[in]": equipmentTypes,
      order: "fields.id",
    }),
  ]);
  return packagesResponse;
};


const getScreenTypes = async () => {
  const [screenTypesResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenScreenTypeFields>({
      content_type: "csTouchscreenScreenType",
      order: "fields.id",
    }),
  ]);
  return screenTypesResponse;
};

const getScreenTypesByPath = async (path: string) => {
  const [screenTypesResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenScreenTypeFields>({
      content_type: "csTouchscreenScreenType",
      "fields.path": path,
    }),
  ]);
  return screenTypesResponse;
};

const getEquipmentTypes = async () => {
  const [equipmentTypesResponse] = await Promise.all([
    client.getEntries<ICsTouchscreenEquipmentTypeFields>({
      content_type: "csTouchscreenEquipmentType",
      order: "-sys.updatedAt",
    }),
  ]);
  return equipmentTypesResponse;
};

export const Api = {
  getProducts,
  getStrings,
  getScreenTypes,
  getScreenTypesByPath,
  getEquipmentTypes,
  getPackagesByEquipmentType,
  getPackagesByEquipmentTypes,
  getPackageBySlug,
};
